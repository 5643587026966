import { Box, Typography, Stack, Button, Skeleton } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { primaryButtonStyles } from '../styles';
import { LEIAAInput } from '../inputs';
import { MatterStatusUpdate } from './types';
import {
  createReplyToStatusUpdate,
  createStatusUpdate,
} from '../../api/general';
import StatusUpdate from './StatusUpdate';

interface StatusUpdatesBodyProps {
  createMode: boolean;
  setCreateMode: any;
  currentUpdate: MatterStatusUpdate | undefined;
  loading: boolean;
  refreshData: any;
  setViewMode: any;
  matterId?: any;
  organisationId?: any;
  dashboard?: any;
}

const StatusUpdatesBody = ({
  createMode,
  setCreateMode,
  currentUpdate,
  loading,
  refreshData,
  setViewMode,
  matterId = false,
  organisationId = false,
  dashboard = false,
}: StatusUpdatesBodyProps) => {
  const { handleSubmit, control, reset } = useForm();

  const onSubmit: SubmitHandler<any> = async (data: any) => {
    try {
      if (createMode) {
        setViewMode('all');
        const response = await createStatusUpdate(matterId, data.update);
        setCreateMode(false);
        refreshData(response.id);
      } else {
        const response = await createReplyToStatusUpdate(
          currentUpdate?.id,
          data.reply
        );
        refreshData(response.id);
      }
    } catch (error: any) {
      // error message component
      console.log(error);
    } finally {
      reset();
    }
  };

  return createMode ? (
    <Stack
      direction="column"
      spacing={2}
      sx={{
        justifyContent: 'flex-end',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <Box sx={{ height: '135%' }} />
      <form
        style={{ width: '100%', height: '20%' }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Stack
          direction="row"
          spacing={0.5}
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Box sx={{ width: '100%' }}>
            <LEIAAInput
              labelText=""
              controllerName="update"
              control={control}
              inputPlaceholder="Describe the current status and any recent actions..."
              inputWidth="100%"
              inputHeight="40px"
            />
          </Box>
          <Button
            variant="contained"
            sx={{ ...primaryButtonStyles(), height: '40px' }}
            type="submit"
          >
            <Typography>Add</Typography>
            <span className="material-icons-round">send</span>
          </Button>
        </Stack>
      </form>
    </Stack>
  ) : (
    <Stack
      direction="column"
      spacing={2}
      sx={{
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '100%',
      }}
    >
      {loading ? (
        <Skeleton variant="rectangular" />
      ) : (
        <Box
          sx={{
            height: '80%',
            overflowY: 'auto',
            marginTop: '15px',
            width: '100%',
          }}
          className="scrollable-content-always-show"
        >
          {currentUpdate && (
            <StatusUpdate
              showMatter={!matterId}
              showOrg={dashboard}
              update={currentUpdate}
            />
          )}
        </Box>
      )}

      <form
        style={{ width: '100%', height: '20%' }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Stack
          direction="row"
          spacing={0.5}
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Box sx={{ width: '100%' }}>
            <LEIAAInput
              labelText=""
              controllerName="reply"
              control={control}
              inputPlaceholder="Add your reply or follow-up question…"
              inputWidth="100%"
              inputHeight="40px"
            />
          </Box>
          <Button
            variant="contained"
            sx={{ ...primaryButtonStyles(), height: '40px' }}
            type="submit"
          >
            <Typography>Reply</Typography>
            <span className="material-icons-round">send</span>
          </Button>
        </Stack>
      </form>
    </Stack>
  );
};

export default StatusUpdatesBody;
