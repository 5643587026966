import {
  Box,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  Snackbar,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';

import { homePortalSearchInputStyles } from '../../../shared/resource-page-header/styles';
import CreationNotification from '../../../shared/creation-notification/CreationNotification';
import NotificationToast from '../../../shared/toast/NotificationToast';
import { karlaProRegularFontStyles } from '../../../../styles/textStyles';
import { getCurrentMonthYear } from '../../../../utils/utils';
import MatterDetailTimelineCalendar from './MatterDetailTimelineCalendar';
import MatterDetailTimelineList from './MatterDetailTimelineList';
import MatterDetailTimelineAddEvent from './MatterDetailTimelineAddEvent';
import { getMatterTimelineEvents } from '../../../../api/matters';
import MatterDetailTimelineEventTypePicker from './MatterDetailTimelineEventTypePicker';
import MatterDetailTimelineRangePicker from './MatterDetailTimelineRangePicker';
import MatterDetailTimelineJumpTo from './MatterDetailTimelineJumpTo';

interface MatterDetailTimelineProps {
  matterId: any;
  matterDetails: any;
}

const eventTypes = [
  'Storypoint',
  'Activity',
  'Evidence',
  'Internal Event',
  'Notes',
];

const MatterDetailTimeline = ({
  matterId,
  matterDetails,
}: MatterDetailTimelineProps) => {
  const [loading, setLoading] = useState(true);
  const inputRef = useRef<HTMLInputElement>(null);
  const [events, setEvents] = useState<any[]>([]);
  const [showArchiveNotification, setShowArchiveNotification] = useState(false);
  const [showEditNotification, setShowEditNotification] = useState(false);
  const [showErrorNotification, setShowErrorNotification] = useState(false);
  const [requestError, setRequestError] = useState<string | null>(null);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [viewMode, setViewMode] = useState('list');
  const [wantedEventTypes, setWantedEventTypes] =
    useState<string[]>(eventTypes);
  const [endDate, setEndDate] = useState();
  const [starDate, setStartDate] = useState();

  const handleFetchEvents = async () => {
    setLoading(true);
    try {
      const response = await getMatterTimelineEvents(
        matterId,
        wantedEventTypes,
        starDate,
        endDate
      );
      setEvents(response);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleToggleChange = (event: any, newView: any) => {
    if (newView) {
      setViewMode(newView);
    }
  };

  const handlePreviousMonth = () => {
    setCurrentDate((prevDate) => {
      const previousMonth = new Date(prevDate);
      previousMonth.setDate(previousMonth.getDate() - 29);
      return previousMonth;
    });
  };

  const handleNextMonth = () => {
    setCurrentDate((prevDate) => {
      const nextMonth = new Date(prevDate);
      nextMonth.setDate(nextMonth.getDate() + 29);
      return nextMonth;
    });
  };

  const formatEntryDate = (date: Date) => {
    const dayOfWeek = date
      .toLocaleDateString('en-US', { weekday: 'short' })
      .toUpperCase();
    const fullDate = date
      .toLocaleDateString('en-US', { day: '2-digit', month: 'long' })
      .split(' ')
      .reverse()
      .join(' ')
      .toUpperCase();

    return { dayOfWeek, fullDate };
  };

  const handleDateClick = (date: Date) => {
    setCurrentDate(date);
    setViewMode('list');
  };

  useEffect(() => {
    handleFetchEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Snackbar
        open={showArchiveNotification}
        autoHideDuration={3000}
        onClose={() => setShowArchiveNotification(false)}
        message="Event archived successfully!"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        sx={{
          zIndex: '99999999',
          '& > div': {
            background: '#38B763',
          },
        }}
      />
      <Snackbar
        open={showEditNotification}
        autoHideDuration={3000}
        onClose={() => setShowEditNotification(false)}
        message="Event saved!"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        sx={{
          zIndex: '99999999',
          '& > div': {
            background: '#38B763',
          },
        }}
      />

      {showErrorNotification && (
        <NotificationToast
          showNotification={showErrorNotification}
          requestError={requestError}
          handleCloseNotification={() => setShowErrorNotification(false)}
        />
      )}

      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          gap: '30px',
          alignSelf: 'stretch',
          '& > div:nth-of-type(1)': {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            flex: '1 0 0',
          },
        }}
      >
        <Box>
          <Stack
            direction="row"
            spacing={4}
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <MatterDetailTimelineAddEvent
              matterId={matterId}
              matterDetails={matterDetails}
              refreshTimeline={handleFetchEvents}
              setShowEditNotification={setShowEditNotification}
            />
            <MatterDetailTimelineEventTypePicker
              eventTypes={eventTypes}
              wantedTypes={wantedEventTypes}
              setWantedTypes={setWantedEventTypes}
              handleFetchEvents={handleFetchEvents}
            />
            <MatterDetailTimelineRangePicker
              handleFetchEvents={handleFetchEvents}
              startDate={starDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
            />
            <MatterDetailTimelineJumpTo
              currentDate={currentDate}
              events={events}
              handleDateClick={handleDateClick}
            />
          </Stack>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: '20px',
            }}
          >
            <TextField
              id="input-with-icon-textfield"
              placeholder="Search event..."
              sx={{
                ...homePortalSearchInputStyles,
                display: 'none',
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{ '& > span': { color: '#69707B' } }}
                  >
                    <span className="material-icons-round">search</span>
                  </InputAdornment>
                ),
                disableUnderline: true,
                onChange: handleFetchEvents,
                inputRef,
              }}
              variant="standard"
            />
          </Box>
        </Box>
      </Box>
      <Box sx={{ width: '100%', marginTop: '40px' }}>
        {/* Calendar Action Row */}
        <Stack
          direction="row"
          spacing={2}
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {viewMode === 'list' ? (
            <Box />
          ) : (
            <>
              <Stack
                direction="row"
                spacing={2}
                sx={{
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Stack
                  sx={{
                    '& > svg': {
                      width: '16px',
                      height: '16px',
                      cursor: 'pointer',
                      color: '#464B53',
                    },
                    '& > p': {
                      ...karlaProRegularFontStyles,
                      fontWeight: 500,
                      fontSize: '16px',
                      lineHeight: '19px',
                      textAlign: 'center',
                      letterSpacing: '0.2px',
                    },
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  direction="row"
                  spacing={2}
                >
                  <IconButton
                    sx={{ padding: 0 }}
                    aria-label="week-back"
                    onClick={handlePreviousMonth}
                  >
                    <span className="material-symbols-rounded">arrow_back</span>
                  </IconButton>
                  <Typography>month</Typography>
                  <IconButton
                    sx={{ padding: 0 }}
                    aria-label="week-forward"
                    onClick={handleNextMonth}
                  >
                    <span className="material-symbols-rounded">
                      arrow_forward
                    </span>
                  </IconButton>
                </Stack>
              </Stack>
              <Typography
                sx={{
                  ...karlaProRegularFontStyles,
                  fontWeight: 500,
                  fontSize: '16px',
                  lineHeight: '19px',
                  textAlign: 'center',
                  letterSpacing: '0.2px',
                }}
              >
                {getCurrentMonthYear(currentDate)}
              </Typography>
            </>
          )}

          <Stack
            direction="row"
            spacing={2}
            sx={{
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <ToggleButtonGroup
              value={viewMode}
              exclusive
              onChange={handleToggleChange}
              sx={{
                backgroundColor: '#0353FF',
                borderRadius: '20px',
                height: '24px',
                '& .MuiToggleButton-root': {
                  color: '#FFF',
                  borderRadius: '20px',
                  '&:hover': {
                    color: '#001033',
                    backgroundColor: 'transparent !important',
                  },
                  '&.Mui-selected': {
                    backgroundColor: '#FFF',
                    color: '#0353FF',
                    fontWeight: 'bold',
                    borderRadius: '17px',
                    '&:hover': {
                      backgroundColor: '#FFF !important',
                    },
                  },
                },
              }}
            >
              <ToggleButton
                value="list"
                sx={{
                  textAlign: 'center',
                  ...karlaProRegularFontStyles,
                  fontWeight: 400,
                  fontSize: '12px',
                  lineHeight: '16px',
                  letterSpacing: '0.2px',
                }}
              >
                List
              </ToggleButton>
              <ToggleButton
                sx={{
                  textAlign: 'center',
                  ...karlaProRegularFontStyles,
                  fontWeight: 400,
                  fontSize: '12px',
                  lineHeight: '16px',
                  letterSpacing: '0.2px',
                }}
                value="calendar"
              >
                Calendar
              </ToggleButton>
            </ToggleButtonGroup>
          </Stack>
        </Stack>
        {/* Body */}
        {viewMode === 'list' ? (
          <MatterDetailTimelineList
            currentDate={currentDate}
            formatEntryDate={formatEntryDate}
            events={events}
            loading={loading}
            matterId={matterId}
            matterDetails={matterDetails}
            refreshTimeline={handleFetchEvents}
            setShowArchiveNotification={setShowArchiveNotification}
            setShowEditNotification={setShowEditNotification}
          />
        ) : (
          <MatterDetailTimelineCalendar
            currentDate={currentDate}
            events={events}
            handleDateClick={handleDateClick}
          />
        )}
      </Box>
    </>
  );
};

export default MatterDetailTimeline;
