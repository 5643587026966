import { Box, Popover, Stack, Typography, IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { karlaProRegularFontStyles } from '../../../../styles/textStyles';
import { LEIAADatePicker } from '../../../inputs';

interface MatterDetailTimelineRangePickerProps {
  handleFetchEvents: any;
  startDate: any;
  setStartDate: any;
  endDate: any;
  setEndDate: any;
}

const MatterDetailTimelineRangePicker = ({
  handleFetchEvents,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}: MatterDetailTimelineRangePickerProps) => {
  const { control, setValue, watch } = useForm({
    defaultValues: {
      startDate,
      endDate,
    } as any,
  });

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const selectedStartDate = watch('startDate');
  const selectedEndDate = watch('endDate');

  useEffect(() => {
    handleFetchEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStartDate, selectedEndDate]);

  const clearStartDate = () => {
    setValue('startDate', null);
    setStartDate(null);
  };

  const clearEndDate = () => {
    setValue('endDate', null);
    setEndDate(null);
  };

  return (
    <Box>
      <Typography
        sx={{
          display: 'flex',
          alignItems: 'center',
          textAlign: 'center',
          fontSize: '16px',
          ...karlaProRegularFontStyles,
          fontWeight: '500',
          letterSpacing: '0.2px',
          cursor: 'pointer',
          color: '#0053FF',
          '&:hover': {
            color: '#0B25B0',
          },
        }}
        onClick={handleClick}
      >
        Date Range
      </Typography>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={{
          boxShadow: 'none !important',
          border: '1px solid #D3ECF7',
        }}
        PaperProps={{
          sx: {
            boxShadow: 'none !important',
          },
        }}
      >
        <Stack
          direction="row"
          spacing={2}
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            padding: '5px 10px',
            border: '1px solid #D3ECF7',
            flexDirection: 'row !important',
            borderRadius: '5px',
          }}
        >
          <Box position="relative" display="inline-flex" alignItems="center">
            <Controller
              name="startDate"
              control={control}
              render={({ field }) => (
                <LEIAADatePicker
                  labelText=""
                  inputPlaceholder="Start Date"
                  controllerName="startDate"
                  control={control}
                  {...field}
                  handleChange={(date: any) => {
                    field.onChange(date);
                    setValue('startDate', date);
                    setStartDate(date);
                  }}
                  required={false}
                  disableHelper
                  useBlueStyles
                />
              )}
            />
            {selectedStartDate && (
              <IconButton
                onClick={clearStartDate}
                size="small"
                sx={{
                  position: 'absolute',
                  top: '50%',
                  right: '25%',
                  transform: 'translate(50%, -50%)',
                  backgroundColor: 'white',
                  zIndex: 1,
                  '&:hover': {
                    backgroundColor: 'lightgray',
                  },
                }}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            )}
          </Box>

          <Box>
            <Typography
              sx={{
                display: 'flex',
                alignItems: 'center',
                color: '#464B53',
                fontSize: '14px',
                ...karlaProRegularFontStyles,
                lineHeight: '22px',
                marginRight: 'auto',
              }}
            >
              to
            </Typography>
          </Box>
          <Box position="relative" display="inline-flex" alignItems="center">
            <Controller
              name="endDate"
              control={control}
              render={({ field }) => (
                <LEIAADatePicker
                  labelText=""
                  inputPlaceholder="End Date"
                  controllerName="endDate"
                  control={control}
                  {...field}
                  handleChange={(date: any) => {
                    field.onChange(date);
                    setValue('endDate', date);
                    setEndDate(date);
                  }}
                  required={false}
                  disableHelper
                  useBlueStyles
                />
              )}
            />
            {selectedEndDate && (
              <IconButton
                onClick={clearEndDate}
                size="small"
                sx={{
                  position: 'absolute',
                  top: '50%',
                  right: '25%',
                  transform: 'translate(50%, -50%)',
                  backgroundColor: 'white',
                  zIndex: 1,
                  '&:hover': {
                    backgroundColor: 'lightgray',
                  },
                }}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            )}
          </Box>
        </Stack>
      </Popover>
    </Box>
  );
};

export default MatterDetailTimelineRangePicker;
