import {
  Box,
  Typography,
  CircularProgress,
  Stack,
  IconButton,
  Button,
  ToggleButtonGroup,
  ToggleButton,
} from '@mui/material';
import { useEffect, useState } from 'react';
import axios from 'axios';
import {
  karlaProRegularFontStyles,
  karlaProRegularItalicFontStyles,
} from '../../styles/textStyles';
import { tertiaryButtonStyles } from '../styles';
import StatusUpdatesBody from './StatusUpdatesBody';
import { getStatusUpdates, markAsReadStatusUpdate } from '../../api/general';
import { MatterStatusUpdate } from './types';

interface StatusUpdatesProps {
  matterId?: any;
  organisationId?: any;
  dashboard?: any;
}

const StatusUpdates = ({
  matterId = false,
  organisationId = false,
  dashboard = false,
}: StatusUpdatesProps) => {
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [statusUpdates, setStatusUpdates] = useState<MatterStatusUpdate[]>([]);
  const [createMode, setCreateMode] = useState<boolean>(false);
  const [viewMode, setViewMode] = useState('new');
  const [currentIndex, setCurrentIndex] = useState<number>(-1);
  const [currentUpdate, setCurrentUpdate] = useState<
    MatterStatusUpdate | undefined
  >(undefined);

  const handleFetchStatusUpdates = async (focus: any = false) => {
    setLoading(true);

    try {
      const response = await getStatusUpdates(
        matterId,
        organisationId,
        dashboard,
        viewMode !== 'new'
      );

      setStatusUpdates(response);

      /* eslint-disable no-nested-ternary */
      if (focus) {
        const focusIndex = response.findIndex(
          (update: MatterStatusUpdate) => Number(update.id) === Number(focus)
        );

        setCurrentIndex(
          focusIndex !== -1 ? focusIndex : response.length > 0 ? 0 : -1
        );
      } else {
        setCurrentIndex(0);
      }
    } catch (error: unknown) {
      if (axios.isAxiosError(error) && error.response) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage(
          'An unknown error related to the status updates occurred.'
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const handleToggleChange = (event: any, newView: any) => {
    if (newView) {
      setViewMode(newView);
    }
  };

  const cancelCreation = () => {
    setCreateMode(false);
  };

  const previousEvent = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevIndex) => prevIndex - 1);
    }
  };

  const nextEvent = () => {
    if (currentIndex < statusUpdates.length - 1) {
      setCurrentIndex((prevIndex) => prevIndex + 1);
    }
  };

  const markAsReadHandler = async () => {
    try {
      await markAsReadStatusUpdate(currentUpdate?.id);
      if (viewMode === 'new') {
        const newUpdates = statusUpdates.filter(
          (_, index) => index !== currentIndex
        );
        setStatusUpdates(newUpdates);
        setCurrentIndex(
          newUpdates.length > 0
            ? Math.min(currentIndex, newUpdates.length - 1)
            : -1
        );
      } else {
        console.log('animation');
        // display animation
      }
    } catch (error: unknown) {
      setErrorMessage('Read tracker error occurred.');
    }
  };

  useEffect(() => {
    setCurrentIndex(-1);
    handleFetchStatusUpdates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewMode]);

  useEffect(() => {
    setCurrentUpdate(statusUpdates[currentIndex !== -1 ? currentIndex : 0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex, statusUpdates]);

  return (
    <Box
      sx={{
        padding: '15px',
        width: '100%',
        height: '570px',
        backgroundColor: '#FFFFFF',
        border: '1px solid #D3ECF7',
        borderRadius: '24px',
      }}
    >
      <Stack
        direction="row"
        spacing={2}
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="h5"
          sx={{
            fontWeight: 400,
            fontSize: '17px',
            lineHeight: '30px',
            color: '#140070',
            '@media (max-width:1400px)': {
              '&::after': {
                content: '"Updates"',
              },
              '&::before': {
                content: '""',
              },
            },
            '@media (min-width:1401px)': {
              '&::after': {
                content: '"Status Updates"',
              },
            },
          }}
        />

        <Stack
          direction="row"
          spacing={0.5}
          sx={{
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          {createMode ? (
            <Button
              variant="contained"
              sx={{
                padding: '0 !important',
                height: '25px',
                '& > p': {
                  color: 'red',
                  textAlign: 'center',
                  ...karlaProRegularFontStyles,
                  fontSize: '16px',
                  fontWeight: 500,
                  lineHeight: 'normal',
                  letterSpacing: '0.2px',
                  textTransform: 'none',
                },
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '8px',
                borderRadius: '12px',
                backgroundColor: '#fff',
                boxShadow: 'unset',
                '&:hover': {
                  backgroundColor: '#fff',
                  boxShadow: 'unset',
                  '& > *': {
                    color: '#FF4D4D',
                  },
                },
              }}
              onClick={cancelCreation}
            >
              <Typography>Cancel</Typography>
            </Button>
          ) : (
            <Button
              variant="contained"
              sx={{
                ...tertiaryButtonStyles(),
                padding: '0 !important',
                height: '25px',
                '& > span': {
                  fontSize: '16px',
                  color: '#1a5eff',
                },
                '&:focus': {
                  boxShadow: 'none',
                },
                display: !matterId ? 'none' : '',
              }}
              onClick={() => setCreateMode(true)}
            >
              <span className="material-icons-round">add</span>
              <Typography>Add</Typography>
            </Button>
          )}
          {!createMode && (
            <ToggleButtonGroup
              value={viewMode}
              exclusive
              onChange={handleToggleChange}
              sx={{
                backgroundColor: '#0353FF',
                borderRadius: '20px',
                height: '24px',
                '& .MuiToggleButton-root': {
                  color: '#FFF',
                  borderRadius: '20px',
                  '&:hover': {
                    color: '#001033',
                    backgroundColor: 'transparent !important',
                  },
                  '&.Mui-selected': {
                    backgroundColor: '#FFF',
                    color: '#0353FF',
                    fontWeight: 'bold',
                    borderRadius: '17px',
                    '&:hover': {
                      backgroundColor: '#FFF !important',
                    },
                  },
                },
              }}
            >
              <ToggleButton
                value="new"
                sx={{
                  textAlign: 'center',
                  ...karlaProRegularFontStyles,
                  fontWeight: 400,
                  fontSize: '12px',
                  lineHeight: '16px',
                  letterSpacing: '0.2px',
                }}
              >
                New
              </ToggleButton>
              <ToggleButton
                sx={{
                  textAlign: 'center',
                  ...karlaProRegularFontStyles,
                  fontWeight: 400,
                  fontSize: '12px',
                  lineHeight: '16px',
                  letterSpacing: '0.2px',
                }}
                value="all"
              >
                All
              </ToggleButton>
            </ToggleButtonGroup>
          )}
        </Stack>
      </Stack>

      {!createMode && (
        <Box sx={{ marginTop: '10px' }}>
          <Stack
            direction="row"
            spacing={2}
            sx={{
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Button
              variant="contained"
              sx={{
                ...tertiaryButtonStyles(),
                height: '25px',
                padding: '0 !important',
                '& > span': {
                  fontSize: '16px',
                  color: '#1a5eff',
                },
                '&:focus': {
                  boxShadow: 'none',
                },
                '@media (max-width:1300px)': {
                  '& .button-text': {
                    display: 'none',
                  },
                  '& .button-short-text': {
                    display: 'inline',
                  },
                },
                '@media (min-width:1301px)': {
                  '& .button-short-text': {
                    display: 'none',
                  },
                  '& .button-text': {
                    display: 'inline',
                  },
                },
              }}
              onClick={markAsReadHandler}
              disabled={!createMode && statusUpdates.length === 0}
            >
              <span className="material-icons-round">done_all</span>
              <Typography className="button-text">Mark as read</Typography>
              <Typography
                className="button-short-text"
                sx={{ display: 'none' }}
              >
                Read
              </Typography>
            </Button>
            <Stack
              sx={{
                '& > svg': {
                  width: '16px',
                  height: '16px',
                  cursor: 'pointer',
                  color: '#464B53',
                },
                '& > p': {
                  ...karlaProRegularFontStyles,
                  fontWeight: 500,
                  fontSize: '16px',
                  lineHeight: '19px',
                  textAlign: 'center',
                  letterSpacing: '0.2px',
                },
                justifyContent: 'center',
                alignItems: 'center',
              }}
              direction="row"
              spacing={2}
            >
              <IconButton
                sx={{ padding: 0 }}
                aria-label="week-back"
                onClick={previousEvent}
                disabled={currentIndex <= 0}
              >
                <span className="material-symbols-rounded">arrow_back</span>
              </IconButton>

              <Typography>Update</Typography>

              <IconButton
                sx={{ padding: 0 }}
                aria-label="week-forward"
                onClick={nextEvent}
                disabled={currentIndex === statusUpdates.length - 1}
              >
                <span className="material-symbols-rounded">arrow_forward</span>
              </IconButton>
            </Stack>
          </Stack>
        </Box>
      )}

      {!createMode && statusUpdates.length === 0 && !loading ? (
        <Box
          sx={{
            height: '100%',
            width: '100',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '0px !important',
          }}
        >
          <Typography
            sx={{ ...karlaProRegularItalicFontStyles, color: '#464B53' }}
          >
            No updates.
          </Typography>
        </Box>
      ) : (
        <StatusUpdatesBody
          createMode={createMode}
          setCreateMode={setCreateMode}
          currentUpdate={currentUpdate}
          matterId={matterId}
          organisationId={organisationId}
          dashboard={dashboard}
          loading={loading}
          refreshData={handleFetchStatusUpdates}
          setViewMode={setViewMode}
        />
      )}
    </Box>
  );
};

export default StatusUpdates;
