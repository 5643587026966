import React, { useContext, useEffect } from 'react';
import * as Sentry from '@sentry/react';
import CacheBuster from 'react-cache-buster';
import { LayoutContextProvider } from '@livekit/components-react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  RouterProvider,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { Box, CircularProgress } from '@mui/material';
import './index.css';
import { Provider } from 'react-redux';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Auth0Provider } from '@auth0/auth0-react';
import reportWebVitals from './reportWebVitals';
import version from '../package.json';
import App from './App';
import Login from './pages/Login';
import LEIAAContextProvider from './context/LEIAAContextProvider';
import ErrorPage from './pages/ErrorPage';
import UserDetails from './pages/UserDetails';
import UserDetailsEdit from './pages/UserDetailsEdit';
import UsersList from './pages/UsersList';
import UsersCreate from './pages/UsersCreate';
import '@livekit/components-styles';
import LEIAAInterview from './pages/LEIAAInterview';
import ResetPassword from './pages/ResetPassword';
import ForgotPassword from './pages/ForgotPassword';
import MatterCreation from './pages/MatterCreation';
import { store } from './app/store';
import MattersList from './pages/MattersList';
import MatterDetail from './pages/MatterDetail';
import MatterDetailBundleDetail from './components/matters/matter-detail/MatterDetailBundleDetail';
import MatterDetailFolderDetail from './components/matters/matter-detail/MatterDetailFolderDetail';
import MatterDetailTaskDetail from './components/matters/matter-detail/MatterDetailTaskDetail';
import WikiList from './pages/WikiList';
import TemplatesCreation from './pages/TemplatesCreation';
import DocumentCreate from './pages/DocumentCreate';
import InterviewCompleted from './pages/InterviewCompleted';
import InterviewDetail from './pages/InterviewDetail';
import MobileWarningPage from './pages/MobileWarningPage';
import WikiFolderDetail from './components/wiki/WikiFolderDetail';
import ClientList from './pages/ClientList';
import ClientDetail from './pages/ClientDetail';
import MatterDetailFraudDetail from './components/matters/matter-detail/MatterDetailFraudDetail';
import AddTask from './pages/matter/AddTask';
import AddDocument from './pages/matter/AddDocument';
import AddInterview from './pages/matter/AddInterview';
import Status from './pages/Status';
import Reporting from './pages/whistleblowing/Reporting';
import ReportingFaq from './pages/whistleblowing/ReportingFaq';
import ReportingFollowLogin from './pages/whistleblowing/ReportingFollowLogin';
import ReportingFollowDashboard from './pages/whistleblowing/ReportingFollowDashboard';
import ReportingForm from './pages/whistleblowing/ReportingForm';
import ReportList from './pages/whistleblowing/leiaa/ReportList';
import ReportDetail from './pages/whistleblowing/leiaa/ReportDetail';
import MyDashboard from './pages/my-dashboard/MyDashboard';
import LEIAAContext from './context/context';
import { userMeDetailsRequest } from './api/auth';
import ExternalFileUploadPortal from './pages/ExternalFileUploadPortal';
import AnalysisList from './pages/AnalysisList';
import DirectDownloadLink from './components/shared/document-detail/DirectDownloadLink';
import MatterDetailReportDetail from './pages/reports/MatterDetailReportDetail';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
    ],
    environment: process.env.NODE_ENV ?? 'local',
    // Performance Monitoring
    tracesSampleRate: 0.1, //  Capture 100% of the transactions
    tracePropagationTargets: [
      'localhost',
      /^https:\/\/portal.augmetec\.com\/api/,
    ],
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const DynamicRoutes = () => {
  const { user, setUser } = useContext(LEIAAContext);

  const navigateToLogin = () => {
    const currentPath = window.location.pathname;

    // Check if the user is already on the login page
    if (currentPath !== '/login') {
      window.location.href = '/login';
    }
  };

  const handleFetchUserDetails = async () => {
    try {
      const userInfo = await userMeDetailsRequest('GET');
      setUser(userInfo.user);
    } catch (error) {
      localStorage.clear();
      navigateToLogin();
    }
  };

  useEffect(() => {
    // Check if the current route requires user details
    if (
      window.location.pathname.startsWith('/matters') ||
      window.location.pathname.startsWith('/users')
    ) {
      handleFetchUserDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const baseRoutes = [
    {
      path: '/login',
      element: <Login />,
    },
    {
      path: '/mobile',
      element: <MobileWarningPage />,
    },
    {
      path: '/status',
      element: <Status />,
    },
    {
      path: '/reset-password',
      element: <ForgotPassword />,
    },
    {
      path: '/reset-password/:twoFactorToken/:reporting',
      element: <ResetPassword />,
    },
    {
      path: '/interviews/:room/preroom/:token',
      element: <LEIAAInterview />,
    },
    {
      path: '/interview/end',
      element: <InterviewCompleted />,
    },
    {
      path: 'not-found',
      element: <ErrorPage />,
    },
    {
      path: '/matter/upload-portal/:token',
      element: <ExternalFileUploadPortal />,
    },
    {
      path: '/direct-link/:token',
      element: <DirectDownloadLink />,
    },
  ];

  if (process.env.REACT_APP_FEATURE_FLAG_WHISTLEBLOWER_REPORTING === 'true') {
    // whistleblowing
    baseRoutes.push(
      {
        path: '/reporting',
        element: <Reporting />,
      },
      {
        path: '/reporting-faq',
        element: <ReportingFaq />,
      },
      {
        path: '/reporting-follow-up',
        element: <ReportingFollowLogin />,
      },
      {
        path: '/reporting-follow-up-dashboard/:id',
        element: <ReportingFollowDashboard />,
      },
      {
        path: '/report-form',
        element: <ReportingForm />,
      }
    );
  }

  const dynamicRoutes = [
    {
      path: '/',
      element: <App />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: '/',
          element: <MyDashboard />,
        },
        // toggled off in favour of my dashboard approach
        // {
        //   path: '/',
        //   element: <HomePortal />,
        // },
        ['Admin', 'Superuser', 'Leader'].includes(user.role) && {
          path: '/users',
          element: <UsersList />,
        },
        {
          path: '/users/create',
          element: <UsersCreate />,
        },
        {
          path: '/users/:userId',
          element: <UsersCreate />,
        },
        {
          path: '/user-details',
          element: <UserDetails />,
        },
        {
          path: '/user-details/:userId',
          element: <UserDetails />,
        },
        {
          path: '/user-details/edit',
          element: <UserDetailsEdit />,
        },
        {
          path: '/user-details/:userId/edit',
          element: <UserDetailsEdit />,
        },
        {
          path: '/matters/new/:matterId',
          element: <MatterCreation />,
        },
        {
          path: '/matters/edit/:matterId',
          element: <MatterCreation />,
        },
        ['Admin', 'Superuser', 'Leader'].includes(user.role) && {
          path: '/matters',
          element: <MattersList />,
        },
        {
          path: '/matters/detail/:matterId',
          element: <MatterDetail />,
        },
        {
          path: '/matters/detail/:matterId/tasks/:taskId',
          element: <MatterDetailTaskDetail />,
        },

        {
          path: '/matters/detail/:matterId/folder/:folderId',
          element: <MatterDetailFolderDetail />,
        },
        {
          path: '/matters/detail/:matterId/bundle/:bundleId',
          element: <MatterDetailBundleDetail />,
        },
        {
          path: '/matters/detail/:matterId/fraud/:resourceId',
          element: <MatterDetailFraudDetail />,
        },
        {
          path: '/matters/detail/:matterId/reports/:reportId',
          element: <MatterDetailReportDetail />,
        },
        // {
        //   path: '/document/:resourceId',
        //   element: <DocumentDetail />,
        // },
        {
          path: '/wiki/folder/:resourceId',
          element: <WikiFolderDetail />,
        },
        // toggled off in favour of my dashboard approach
        // {
        //   path: '/interviews',
        //   element: <InterviewList />,
        // },
        {
          path: '/interviews/:interviewId',
          element: <InterviewDetail />,
        },
        {
          path: '/matters/detail/:matterId/interviews/:interviewId',
          element: <InterviewDetail />,
        },
        {
          path: '/insights',
          element: <AnalysisList />,
        },
        {
          path: '/wiki',
          element: <WikiList />,
        },
        {
          path: '/wiki/templates/new',
          element: <TemplatesCreation />,
        },
        {
          path: '/wiki/templates/edit/:templateId',
          element: <TemplatesCreation />,
        },
        {
          path: '/wiki/repository/new/document',
          element: <DocumentCreate />,
        },
        {
          path: '/clients',
          element: <ClientList />,
        },
        {
          path: '/client/:clientId',
          element: <ClientDetail />,
        },
        process.env.REACT_APP_FEATURE_FLAG_WHISTLEBLOWER_REPORTING === 'true' &&
          ['Admin', 'Superuser', 'Leader'].includes(user.role) && {
            path: '/reports',
            element: <ReportList />,
          },
        process.env.REACT_APP_FEATURE_FLAG_WHISTLEBLOWER_REPORTING === 'true' &&
          ['Admin', 'Superuser', 'Leader'].includes(user.role) && {
            path: '/report/:id',
            element: <ReportDetail />,
          },
        // single adds
        {
          path: '/matter/addTask/:matterId',
          element: <AddTask />,
        },
        {
          path: '/matter/addDocument/:matterId/:folderId',
          element: <AddDocument />,
        },
        {
          path: '/matter/addInterview/:matterId',
          element: <AddInterview />,
        },
      ],
    },
  ];

  const routes = [...baseRoutes, ...dynamicRoutes];

  if (process.env.NODE_ENV === 'production') {
    const sentryCreateBrowserRouter =
      Sentry.wrapCreateBrowserRouter(createBrowserRouter);
    const router = sentryCreateBrowserRouter(routes);
    return <RouterProvider router={router} />;
  }

  const router = createBrowserRouter(routes);
  return <RouterProvider router={router} />;
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// note removed <React.StrictMode> due to makign every drag and drop lib not work
// - would like to keep strict mode for dev, check this when we get more time
root.render(
  <CacheBuster
    currentVersion={version.version}
    isEnabled
    isVerboseMode={false} // If true, the library writes verbose logs to console.
    loadingComponent={
      <Box
        sx={{
          height: '100vh', // Full viewport height
          width: '100vw', // Full viewport width
          background: 'white',
          display: 'flex',
          justifyContent: 'center', // Centers horizontally
          alignItems: 'center', // Centers vertically
        }}
      >
        <CircularProgress />
      </Box>
    } // If not pass, nothing appears at the time of new version check.
    metaFileDirectory="." // If public assets are hosted somewhere other than root on your server.
  >
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_PROVIDER_DOMAIN as string}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID as string}
      authorizationParams={{
        redirect_uri: process.env.REACT_APP_AUTH0_REDIRECT_URI,
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      }}
    >
      <Provider store={store}>
        <LEIAAContextProvider>
          <LayoutContextProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DynamicRoutes />
            </LocalizationProvider>
          </LayoutContextProvider>
        </LEIAAContextProvider>
      </Provider>
    </Auth0Provider>
  </CacheBuster>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
