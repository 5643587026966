import {
  Box,
  Checkbox,
  FormControlLabel,
  InputLabel,
  Popover,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { karlaProRegularFontStyles } from '../../../../styles/textStyles';

interface MatterDetailTimelineEventTypePickerProps {
  eventTypes: string[];
  handleFetchEvents: any;
  wantedTypes: string[];
  setWantedTypes: any;
}

const MatterDetailTimelineEventTypePicker = ({
  eventTypes,
  handleFetchEvents,
  wantedTypes,
  setWantedTypes,
}: MatterDetailTimelineEventTypePickerProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [checked, setChecked] = useState<boolean[]>(
    new Array(eventTypes.length).fill(true)
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleChangeAll = () => {
    const allChecked = checked.every((item) => item);
    const newChecked = new Array(eventTypes.length).fill(!allChecked);
    setChecked(newChecked);
    setWantedTypes(allChecked ? [] : eventTypes);
  };

  const handleChangeType = (index: number) => {
    const newChecked = checked.slice();
    newChecked[index] = !checked[index];
    setChecked(newChecked);
    setWantedTypes(
      newChecked
        .map((isChecked, i) => (isChecked ? eventTypes[i] : null))
        .filter(Boolean) as string[]
    );
  };

  useEffect(() => {
    handleFetchEvents(wantedTypes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wantedTypes]);
  return (
    <Box>
      <Typography
        sx={{
          display: 'flex',
          alignItems: 'center',
          textAlign: 'center',
          fontSize: '16px',
          ...karlaProRegularFontStyles,
          fontWeight: '500',
          letterSpacing: '0.2px',
          cursor: 'pointer',
          color: '#0053FF',
          '&:hover': {
            color: '#0B25B0',
            '& > span': {
              color: '#0B25B0',
            },
          },
          '& > span': {
            color: '#0053FF',
            fontSize: '30px',
          },
        }}
        onClick={handleClick}
      >
        Event Types
        <span className="material-icons-round">arrow_drop_down</span>
      </Typography>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={{
          boxShadow: 'none !important',
          border: '1px solid #D3ECF7',
        }}
        PaperProps={{
          sx: {
            boxShadow: 'none !important',
          },
        }}
      >
        <Box
          style={{
            padding: '5px 0px 5px 10px',
            border: '1px solid #D3ECF7',
            borderRadius: '5px',
          }}
        >
          <FormControlLabel
            label={
              <InputLabel
                data-testid="matter-form-label-id"
                sx={{
                  color: '#464B53',
                  fontSize: '14px',
                  ...karlaProRegularFontStyles,
                  lineHeight: '22px',
                  display: 'flex',
                  marginRight: 'auto',
                }}
              >
                All
              </InputLabel>
            }
            control={
              <Checkbox
                checked={checked.every((item) => item)}
                indeterminate={
                  !checked.every((item) => item) && checked.some((item) => item)
                }
                onChange={handleChangeAll}
                sx={{
                  '& .MuiSvgIcon-root': { fontSize: 20 },
                }}
              />
            }
          />
          <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
            {eventTypes.map((type, index) => (
              <FormControlLabel
                key={type}
                label={
                  <InputLabel
                    data-testid="matter-form-label-id"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      color: '#464B53',
                      fontSize: '14px',
                      ...karlaProRegularFontStyles,
                      lineHeight: '22px',
                      marginRight: 'auto',
                    }}
                  >
                    {type}
                  </InputLabel>
                }
                control={
                  <Checkbox
                    checked={checked[index]}
                    onChange={() => handleChangeType(index)}
                    sx={{
                      '& .MuiSvgIcon-root': { fontSize: 20 },
                    }}
                  />
                }
              />
            ))}
          </Box>
        </Box>
      </Popover>
    </Box>
  );
};

export default MatterDetailTimelineEventTypePicker;
